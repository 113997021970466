export const routes = {
  financingSummary: '/resumo',
  basicInfo: '/infos-basicas',
  address: '/endereco',
  emailVerification: '/verificacao/email',
  checkoutEmailVerification: '/verificacao',
  phoneVerification: '/verificacao/telefone',
  preSelfie: '/pre-selfie',
  preDocument: '/pre-documento',
  selfie: '/selfie',
  insertCNH: '/cnh',
  insertRGFront: '/rg-frente',
  insertRGBack: '/rg-verso',
  endRequest: '/sucesso',
  contractSummary: '/resumo-contrato',
  invalidRequest: '/requisicao-invalida',
  financingSuccess: '/sucesso-financiamento',
  startReview: '/revisao-dados',
  endReview: '/confirmacao-dados',
}
